














import { Component, Vue, Prop } from 'vue-property-decorator';
import IntervalButtons from '@/components/visualization-metric/IntervalButtons.vue';
import { buttonIntervalsValues, ITextMetric } from '@/types/metric';
import { store } from '@/store';
import { ITimestamp } from '@/types/common';

@Component({ name: 'Intervals', components: { IntervalButtons } })
export default class Intervals extends Vue {
  @Prop() private readonly metricId!: number;
  @Prop() protected readonly data!: buttonIntervalsValues;
  @Prop() protected readonly text!: ITextMetric;

  public intervals: Array<ITimestamp> = []

  get titleText() {
    let titleText = '';
    Object.values(this.text).forEach(({ title, value, unit }) => {
      if (typeof value === 'number') value = value.toFixed(1);
      titleText += `${title}: ${value} ${unit}<br>`;
    });
    return titleText;
  }

  mounted() {
    this.prepareData();
    store.player.mutations.saveIntervals({ intervals: this.intervals, merticId: this.metricId });
  }

  prepareData() {
    const intervals: Array<ITimestamp> = [];
    this.data.forEach(({ from, to }) => {
      intervals.push({
        from,
        to,
        label: `${this.$secondsToTime(from)} - ${this.$secondsToTime(to)}`,
      });
    });
    this.intervals = intervals;
  }
}
